import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import classnames from "classnames";

import Logo from "../logo/Logo";
import DesktopNav from "../navigation/DesktopNav";
import MobileNav from "../navigation/MobileNav";
import SmallSearchBar from "../search-bar/SmallSearchBar";

import "./header.scss";
import MobileHamburger from "../navigation/MobileHamburger";

export default function Header() {
    const location = useLocation();
    const { pathname } = location;
    const [isHomePage, setHomePage] = useState(false);
    const [isMobileMenuOpen, setStateMobileMenu] = useState(false);
    const [isHeaderTransparent, setHeaderTransparent] = useState(true);
    const [isHeaderFloating, setHeaderFloating] = useState(true);

    useEffect(() => {
        // close mobile menu when change route
        setStateMobileMenu(false);
    }, [pathname]);

    useEffect(() => {
        setHomePage(location.pathname === "/");
    }, [pathname]);

    useEffect(() => {
        const scrollWindowHandler = () => {
            const distanceY = window.pageYOffset;
            if (distanceY > 400 && isHomePage) {
                setHeaderTransparent(false);
                setHeaderFloating(true);
            }
            if (distanceY < 100 && isHomePage) {
                setHeaderTransparent(true);
                setHeaderFloating(false);
            }
        };

        if (isHomePage) {
            setHeaderTransparent(true);
            setHeaderFloating(true);

            window.addEventListener("scroll", scrollWindowHandler);
            return () =>
                window.removeEventListener("scroll", scrollWindowHandler);
        } else {
            setHeaderTransparent(false);
            setHeaderFloating(false);
        }
    }, [isHomePage]);

    const headerClasses = classnames({
        "component-header": true,
        transparent: isHeaderTransparent,
        floating: isHeaderFloating,
        "mobile-open": isMobileMenuOpen,
        "is-homepage": isHomePage,
        "card-502circle": pathname === "/card/502circle",
        "card-alliance412": pathname === "/card/alliance412",
        "card-garnettrust": pathname === "/card/garnettrust",
    });

    return (
        <header className={headerClasses}>
            <div className="container">
                <div className="header-wrapper">
                    <div className="header__logo">
                        <Logo
                            type={
                                isHeaderTransparent && !isMobileMenuOpen
                                    ? "color"
                                    : "white"
                            }
                        />
                    </div>
                    <div className="header__searchbar">
                        <SmallSearchBar />
                    </div>
                    <div className="header__navigation">
                        <DesktopNav />
                    </div>
                    <div className="header__hamburger">
                        <MobileHamburger
                            isOpen={isMobileMenuOpen}
                            click={(s) => setStateMobileMenu(s)}
                        />
                    </div>
                </div>
            </div>
            <MobileNav isOpen={isMobileMenuOpen} />
        </header>
    );
}
