import { useParams } from "react-router-dom";
import classnames from "classnames";

import RxCard from "../../components/rx-card/RxCard";
import RxCardActions from "../../components/rx-card-actions/RxCardActions";
import ListBenefitsItems from "../../components/list-benefits-items/ListBenefitsItems";
import PageSectionSubscription from "../../components/page-section-subscription/PageSectionSubscription";

import "./get-card-page.scss";

function GetCardPage() {
    const { rxgrp } = useParams();

    const classes = classnames({
        "page-get-card": true,
        [`page-${rxgrp}`]: rxgrp,
    });

    return (
        <div className={classes}>
            <div className="container">
                <div className="row">
                    <div className="page-get-card__content">
                        <div className="page-get-card__content--title">
                            <h1 className="page-title">Get Your Card Today</h1>
                            <p className="subtitle">
                                Download your CharityRx discount card and simply
                                present it to the pharmacist on your next
                                prescription purchase for instant savings!
                            </p>
                        </div>
                        <div className="page-get-card__content--rxcard">
                            <RxCard cardName={rxgrp} />
                            <RxCardActions cardName={rxgrp} />
                        </div>
                        <div className="page-get-card__content--info">
                            <ListBenefitsItems
                                iconClassName={`icon-${rxgrp}`}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <PageSectionSubscription />
                </div>
            </div>
        </div>
    );
}

export default GetCardPage;
