import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import CardImage from "./images/new/card.png";
import CardImage2x from "./images/new/card2x.png";
import CardImage3x from "./images/new/card3x.png";

import "./rx-card.scss";

const { REACT_APP_CDN_URL } = process.env;

function RxCard({ withPattern, cardName }) {
    const navigate = useNavigate();

    const makeCdnUrl = (cardName, isSrcSet) => {
        if (!REACT_APP_CDN_URL) return null;

        if (!isSrcSet) {
            return `${REACT_APP_CDN_URL}/png/charityrx-card_${cardName.toUpperCase()}.png`;
        } else {
            return `${REACT_APP_CDN_URL}/png/charityrx-card_${cardName.toUpperCase()}-2x.png 2x, ${REACT_APP_CDN_URL}/png/charityrx-card_${cardName.toUpperCase()}-3x.png 3x`;
        }
    };
    const onErrorHandler = () => {
        return navigate("/card");
    };

    return (
        <div className="component__rxcard">
            {withPattern && <div className="component__rxcard--pattern" />}
            {!cardName && (
                <img
                    className="component__rxcard--card"
                    src={CardImage}
                    srcSet={`${CardImage2x} 2x, ${CardImage3x} 3x`}
                    alt="CharityRx Card"
                    draggable={false}
                />
            )}
            {cardName && (
                <img
                    className="component__rxcard--card"
                    src={makeCdnUrl(cardName)}
                    srcSet={makeCdnUrl(cardName, true)}
                    alt={`CharityRx ${cardName} Card`}
                    draggable={false}
                    onError={onErrorHandler}
                />
            )}
        </div>
    );
}

RxCard.propTypes = {
    withPattern: PropTypes.bool,
    cardName: PropTypes.string,
};

RxCard.defaultProps = {
    withPattern: false,
};

export default RxCard;
