import { NavLink, useLocation } from "react-router-dom";
import classnames from "classnames";

import Logo from "../logo/Logo";
import FooterNav from "../navigation/FooterNav";
import SocialIcon from "../social-icon/SocialIcon";
import AppDownloadBtns from "../app-download/AppDownloadBtns";

import "./footer.scss";

const SocialFooterList = () => {
    return (
        <div className="component__footer-social-list">
            <ul className="footer__social-list">
                <li className="footer__social-list--item">
                    <SocialIcon name="facebook" />
                </li>
                <li className="footer__social-list--item">
                    <SocialIcon name="instagram" />
                </li>
                <li className="footer__social-list--item">
                    <SocialIcon name="twitter" />
                </li>
                <li className="footer__social-list--item">
                    <SocialIcon name="youtube" />
                </li>
                <li className="footer__social-list--item">
                    <SocialIcon name="linkedin" />
                </li>
            </ul>
        </div>
    );
};

export default function Footer() {
    const { pathname } = useLocation();
    const customColorSchemePages = [
        "/card/502circle",
        "/card/alliance412",
        "/card/garnettrust",
    ];
    const footerClasses = classnames({
        footer: true,
        "card-502circle": pathname === "/card/502circle",
        "card-alliance412": pathname === "/card/alliance412",
        "card-garnettrust": pathname === "/card/garnettrust",
    });

    return (
        <footer className={footerClasses}>
            <div className="footer__container">
                <div className="footer__container--block-content">
                    <div className="footer__block-content--logo">
                        <Logo
                            type={
                                customColorSchemePages.includes(pathname)
                                    ? "white"
                                    : "color"
                            }
                        />
                    </div>
                    <div className="footer__block-content--disclamer">
                        <p>
                            This program is privately supported. Discounts are
                            only available at participating pharmacies. This
                            program/card is a drug coupon. This is not a
                            medicare prescription drug plan. This is not a
                            government run/affiliated/funded program. This is
                            not insurance. CharityRx does not provide medical
                            advice, treatment, or diagnosis.
                        </p>
                    </div>
                    <SocialFooterList />
                    <div className="footer__block-content--bottom">
                        <div className="bottom-links">
                            <ul>
                                <li>
                                    <NavLink
                                        to="/terms-conditions"
                                        className="link"
                                    >
                                        Terms &amp; Conditions
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/privacy-policy"
                                        className="link"
                                    >
                                        Privacy Policy
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="bottom-copyright">
                            <p>
                                Copyright © {new Date().getFullYear()} CharityRx
                            </p>
                        </div>
                    </div>
                </div>
                <div className="footer__container--block-nav">
                    <FooterNav />
                    {pathname !== "/app" && (
                        <div className="footer__container--block-nav-btns hide-on-mobile">
                            <AppDownloadBtns />
                        </div>
                    )}
                    <div className="just-for-mobile">
                        <ul>
                            <li>
                                <NavLink to="/terms-conditions">
                                    Terms &amp; Conditions
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/privacy-policy">
                                    Privacy Policy
                                </NavLink>
                            </li>
                        </ul>

                        {pathname !== "/app" && (
                            <div className="footer__container--block-nav-btns">
                                <AppDownloadBtns />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </footer>
    );
}
